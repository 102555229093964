import React from "react";
import Bip from "../../components/Bip/index";
import 'bootstrap/dist/css/bootstrap.min.css'
<link rel="shortcut icon" type="image/x-icon" href="../../../public/gf.png"></link>

export default function Home() {
 

    return (
        <div>
            <div className="bipador">
                <Bip />
            </div>
        </div>
    )
}