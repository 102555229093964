// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BatchSaveModal_body__e5rKH {\r\n  align-self: center;\r\n}\r\n\r\n.BatchSaveModal_modalBody__P5gy1{\r\n  height: 65vh;\r\n}\r\n\r\n.BatchSaveModal_modalFooter__cld1q {\r\n  display: flex;\r\n  width: 100%;\r\n  align-items: center;\r\n  flex-direction: row;\r\n  justify-content: space-between;\r\n\r\n}\r\n\r\n.BatchSaveModal_modalInput__VF5g9 {\r\n  width: 100%;\r\n  justify-content: flex-start;\r\n}\r\n\r\n.BatchSaveModal_modalButtons__gJftC {\r\n  display: flex;\r\n  justify-content: flex-end;\r\n  width: 100%;\r\n}\r\n\r\n.BatchSaveModal_input__3pCBT{\r\n  width: 100%;\r\n}\r\n\r\n.BatchSaveModal_tableFooter__katF6 {\r\n  text-align: center;\r\n}", "",{"version":3,"sources":["webpack://./src/components/BatchSaveModal/BatchSaveModal.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;;AAEhC;;AAEA;EACE,WAAW;EACX,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".body {\r\n  align-self: center;\r\n}\r\n\r\n.modalBody{\r\n  height: 65vh;\r\n}\r\n\r\n.modalFooter {\r\n  display: flex;\r\n  width: 100%;\r\n  align-items: center;\r\n  flex-direction: row;\r\n  justify-content: space-between;\r\n\r\n}\r\n\r\n.modalInput {\r\n  width: 100%;\r\n  justify-content: flex-start;\r\n}\r\n\r\n.modalButtons {\r\n  display: flex;\r\n  justify-content: flex-end;\r\n  width: 100%;\r\n}\r\n\r\n.input{\r\n  width: 100%;\r\n}\r\n\r\n.tableFooter {\r\n  text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "BatchSaveModal_body__e5rKH",
	"modalBody": "BatchSaveModal_modalBody__P5gy1",
	"modalFooter": "BatchSaveModal_modalFooter__cld1q",
	"modalInput": "BatchSaveModal_modalInput__VF5g9",
	"modalButtons": "BatchSaveModal_modalButtons__gJftC",
	"input": "BatchSaveModal_input__3pCBT",
	"tableFooter": "BatchSaveModal_tableFooter__katF6"
};
export default ___CSS_LOADER_EXPORT___;
