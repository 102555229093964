// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdditionModal_body__XwWvu{\n  align-self: center;\n}\n\n.AdditionModal_modalFooter__2klSN {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  flex-direction: row;\n  justify-content: space-between;\n\n}\n\n.AdditionModal_modalInput__yDzN3 {\n  width: 100%;\n  justify-content: flex-start;\n}\n\n.AdditionModal_modalButtons__hXaW5 {\n  display: flex;\n  justify-content: flex-end;\n  gap: 8px;\n  width: 100%;\n}\n\n.AdditionModal_input__ACUER{\n  width: 100%;\n}\n\n.AdditionModal_tableFooter__lmP6q {\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/components/AdditionModal/AdditionModal.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;;AAEhC;;AAEA;EACE,WAAW;EACX,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,QAAQ;EACR,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".body{\n  align-self: center;\n}\n\n.modalFooter {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  flex-direction: row;\n  justify-content: space-between;\n\n}\n\n.modalInput {\n  width: 100%;\n  justify-content: flex-start;\n}\n\n.modalButtons {\n  display: flex;\n  justify-content: flex-end;\n  gap: 8px;\n  width: 100%;\n}\n\n.input{\n  width: 100%;\n}\n\n.tableFooter {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "AdditionModal_body__XwWvu",
	"modalFooter": "AdditionModal_modalFooter__2klSN",
	"modalInput": "AdditionModal_modalInput__yDzN3",
	"modalButtons": "AdditionModal_modalButtons__hXaW5",
	"input": "AdditionModal_input__ACUER",
	"tableFooter": "AdditionModal_tableFooter__lmP6q"
};
export default ___CSS_LOADER_EXPORT___;
