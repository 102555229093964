// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tableLotes_txtFileImage__7zhiZ {\r\n  text-align: center;\r\n}\r\n\r\n.tableLotes_buttonsIcon__j8KuI {\r\n  cursor: pointer;\r\n  text-align: center;\r\n  transition: 0.2s all;\r\n}\r\n\r\n.tableLotes_buttonsIcon__j8KuI:active {\r\n  transform: scale(0.9);\r\n}\r\n\r\ntd {\r\n  vertical-align: middle !important;\r\n  text-align: center;\r\n}\r\n\r\n.tableLotes_customMaxWidth__US9yV {\r\n  text-align: center;\r\n  white-space: nowrap;\r\n  overflow: hidden;\r\n  margin-right: 0.5rem;\r\n}", "",{"version":3,"sources":["webpack://./src/components/TableLotes/tableLotes.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,iCAAiC;EACjC,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,oBAAoB;AACtB","sourcesContent":[".txtFileImage {\r\n  text-align: center;\r\n}\r\n\r\n.buttonsIcon {\r\n  cursor: pointer;\r\n  text-align: center;\r\n  transition: 0.2s all;\r\n}\r\n\r\n.buttonsIcon:active {\r\n  transform: scale(0.9);\r\n}\r\n\r\ntd {\r\n  vertical-align: middle !important;\r\n  text-align: center;\r\n}\r\n\r\n.customMaxWidth {\r\n  text-align: center;\r\n  white-space: nowrap;\r\n  overflow: hidden;\r\n  margin-right: 0.5rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"txtFileImage": "tableLotes_txtFileImage__7zhiZ",
	"buttonsIcon": "tableLotes_buttonsIcon__j8KuI",
	"customMaxWidth": "tableLotes_customMaxWidth__US9yV"
};
export default ___CSS_LOADER_EXPORT___;
